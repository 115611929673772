<template>
  <div>
    <div>
  <!-- Tabs -->
  <div class="tabs-container">
  <v-tabs
      v-if="visibleTabs.length > 1"
      v-model="step"
      bg-color="deep-purple-darken-4"
      center-active
      class="justify-center  tab"
    >
      <v-tab v-for="(tab, index) in visibleTabs" :key="index" class="mb-3">
        {{ tab }}
      </v-tab>
    </v-tabs>
</div>

  <!-- Dynamically load the component -->
  <component :is="currentStepComponent" :show="true" @changePrev="handleChangePrev"  @change="handleChange"  :visible-tabs-length="visibleTabs.length"  />
  </div>


  <div v-if="visibleTabs.length > 1" class="d-flex justify-content-end mt-4" >
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
        {{ $t("NEXT") }}
        <div class="loading ml-2" v-if="isLoading">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
    <Snackbar ref="snackbar" class="mt-5" />
</div>
</template>

<script>
import stepInformationBarEN101 from "@/components/stepper/devisEN101/stepInformationBarEN101";
import stepInformationBarEN102 from "@/components/stepper/devisEN102/stepInformationBarEN102";
import stepInformationBarEN103 from "@/components/stepper/devisEN103/stepInformationBarEN103";
import stepInformationBarEN104 from "@/components/stepper/devisEN104/stepInformationBarEN104";
import stepInformationBarTH173 from "@/components/stepper/devisTH173/stepInformationBarTH173";

import stepInformationBar101 from "@/components/stepper/devis101/stepInformationBar101";
import stepInformationBar112 from "@/components/stepper/devis112/stepInformationBar112";
import stepInformationBar113 from "@/components/stepper/devis113/stepInformationBar113";
import stepInformationBarTH137 from "@/components/stepper/devisTH137/stepInformationBarTH137";
import stepInformationBar143 from "@/components/stepper/devis143/stepInformationBar143";
import stepInformationBarTH148 from "@/components/stepper/devisTH148/stepInformationBarTH148";
import stepInformationBar171 from "@/components/stepper/devis171/stepInformationBar171";
import stepInformationBarTH127 from "@/components/stepper/devisTH127/stepInformationBarTH127";
import stepInformationBarTH129 from "@/components/stepper/devisTH129/stepInformationBarTH129";
import stepInformationBarTH174 from "@/components/stepper/devisTH174/stepInformationBarTH174";
import { mapActions,mapGetters } from "vuex";

import Snackbar from "@/components/ui/errorSnackbar.vue";

export default {
  components: {
    Snackbar,
    stepInformationBarTH129,
    stepInformationBarTH127,
    stepInformationBar171,
    stepInformationBarTH148,
    stepInformationBarTH173,
    stepInformationBar143,
    stepInformationBarTH137,
    stepInformationBar113,
    stepInformationBar112,
    stepInformationBar101,
    stepInformationBarEN101,
    stepInformationBarEN102,
    stepInformationBarEN103,
    stepInformationBarEN104,
    stepInformationBarTH174,
  },
  props: {
  
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      step: 0,
      errorMessage: "",
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      "getOnlineUser",
      "getdevisMonoFiche",
    ]),
    visibleTabs() {
  const groupeLigneDocument = this.getdevisMonoFiche?.data?.groupeLigneDocument || [];
 
  const typeGroups = groupeLigneDocument.map((item) => item.type_group);
  const typeDevis =this.getdevisMonoFiche?.data?.devis?.type_devis
  if (typeDevis === "renovation d'ampleur") {
   return [typeDevis, ...typeGroups]
  }else{
    return typeGroups;
  }
 
},

  currentStepComponent() {
    const stepComponents = {
      "BAR-EN-101": "stepInformationBarEN101",
      "BAR-EN-102": "stepInformationBarEN102",
      "BAR-EN-103": "stepInformationBarEN103",
      "BAR-EN-104": "stepInformationBarEN104",
      "BAR-TH-129": "stepInformationBarTH129",
      "BAR-TH-127": "stepInformationBarTH127",
      "BAR-TH-171": "stepInformationBar171",
      "BAR-TH-148": "stepInformationBarTH148",
      "BAR-TH-143": "stepInformationBar143",
      "BAR-TH-173": "stepInformationBarTH173",
      "BAR-TH-113": "stepInformationBar113",
      "BAR-TH-137": "stepInformationBarTH137",
      "BAR-TH-112": "stepInformationBar112",
      "BAR-TH-101": "stepInformationBar101",
      "renovation d'ampleur":"stepInformationBarTH174",
  
    };
    const currentTab = this.visibleTabs[this.step];
    return stepComponents[currentTab] || "div"; 
  },
},


  methods: {
    ...mapActions(["setDevisMonoFiche"]),
    handleChangePrev(value) {
      if(value==true){
        this.$emit("changeStep", 4);
      }else{
        return
      }
    },
    handleChange(value) {
      if(value==true){
        this.$emit("changeStep", 6, true);
      }else{
        return
      }
    },
    valideDevis() {
      this.errorMessage = "";
    const devisData = this.getdevisMonoFiche.data.devis;
    const groupeLigneDocuments =
        this.getdevisMonoFiche.data.groupeLigneDocument || [];

    for (const ligne of groupeLigneDocuments) {
        const typeGroup = ligne.type_group;

        const typeValid =
            (typeGroup === "BAR-EN-101" && devisData.isolation_combles_toiture !== null ) ||
            (typeGroup === "BAR-EN-102" && devisData.isolation_murs !== null) ||
            (typeGroup === "BAR-EN-103" && devisData.isolation_plancher !== null ) ||
            (typeGroup === "BAR-TH-171" && devisData.systeme_chauffage !== null) ||
            (typeGroup === "BAR-TH-143" && devisData.systeme_solaire !== null ) ||
            (typeGroup === "BAR-TH-101" && devisData.chauffe_eau !== null) ||
            (typeGroup === "BAR-TH-113" && devisData.chaudiere_biomasse !== null ) ||
            (typeGroup === "BAR-TH-112" && devisData.chauffage_au_bois !== null ) ||
            (typeGroup === "BAR-TH-127" && devisData.systeme_ventilation_mecanique_controlee !== null) ||
            (typeGroup === "BAR-TH-129" && devisData.pompe_chaleur_air_air !== null) ||
            (typeGroup === "BAR-TH-148" && devisData.chauffe_eau_thermodynamiques !== null) ||
            (typeGroup === "BAR-TH-137" && devisData.raccordement_batiment_residentiel_reseau_chaleur !== null ) ||
            (typeGroup === "BAR-EN-104" && devisData.fenetre_vitrage_complet !== null ) ||
            (typeGroup === "BAR-TH-173" && devisData.systeme_regulation_horaire !== null)||
            // (typeGroup === "BAR-TH-174" && devisData.renovation_ampleur_individuelle !== null )||
            (typeGroup === "renovation d'ampleur" && devisData.renovation_ampleur_individuelle !== null )
        if (!typeValid) {
          
            this.errorMessage = 'Il faut remplir et confirmer toutes les étapes';
             this.isLoading = false;
            break;
        }
    }

  
},

async goToNextStep() {
    this.isLoading = true;
    this.valideDevis();
    if (this.errorMessage !== "") {
      this.$refs.snackbar.showSnackbar(this.errorMessage);
        this.isLoading = false;
        return;
    }

    this.$emit("changeStep", 6, true);
    this.isLoading = false;
},

    goToPrevStep() {
      this.$emit("changeStep", 4);
    },
  },
};
</script>
<style scoped>
.tabs-container {
  display: flex;
  justify-content: center; /* Centre le composant horizontalement */
}
footer {
  @media only screen and (max-width: 900px) {
    padding: 0;
  }
  padding: 24px 12px;
  background-color: #fff;
  border-radius: 7px;
}

footer .type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

footer .type .type__doc {
  width: 24.5%;
  padding: 8px 0;
  text-align: center;
  font-size: 12px;
  background-color: #e9ecef;
  color: #000;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
}

footer .type .active {
  background-color: #f5f6f8;
  font-weight: 600;
  font-size: 14px;
  position: relative;
}

footer .type .active::after {
  @media only screen and (max-width: 900px) {
    left: 25%;
  }
  content: "";
  width: 40px;
  border-top: 2px solid #28367a;
  position: absolute;
  left: 40%;
  bottom: 7px;
}

footer .liste {
  padding: 8px;
  box-shadow: 1px 1px 2px 0px #15223214;
  background-color: #f5f6f8;
  cursor: pointer;
  top: -2px;
  position: relative;
}

footer .liste p {
  margin: 0;
  text-align: center;
}

footer .liste .client_file,
footer .liste .devis_facture {
  border-radius: 7px;
}

footer .liste .client_file h2,
footer .liste .devis_facture h2,
footer .liste .client_file p,
footer .liste .devis_facture p {
  margin: 0;
  display: flex;
  justify-content: center;
  width: 113px;
}

footer .liste .client_file h2,
footer .liste .devis_facture h2 {
  @media only screen and (max-width: 900px) {
    font-size: 12px;
  }
  overflow: hidden;
  font-size: 14px;
  color: #000;
  width: 128px;
}

footer .liste .client_file p,
footer .liste .devis_facture p {
  @media only screen and (max-width: 900px) {
    font-size: 10px;
  }
  font-size: 12px;
  color: #515151;
}

footer .liste .client_file .status,
footer .liste .devis_facture .status {
  @media only screen and (max-width: 900px) {
    min-width: 64px;
    max-width: 64px;
  }
  background-color: #ffb300;
  color: #fff;
  min-width: 75px;
  max-width: 75px;
  padding: 4px;
  border-radius: 14px;
}

footer .liste .client_file .stat,
footer .liste .devis_facture .stat {
  @media only screen and (max-width: 900px) {
    min-width: 64px;
    max-width: 64px;
  }
  background-color: #e4261b;
  color: #fff;
  min-width: 75px;
  max-width: 75px;
  padding: 4px;
  border-radius: 14px;
}

footer .liste .client_file .none,
footer .liste .devis_facture .none {
  @media only screen and (max-width: 900px) {
    min-width: 64px;
    max-width: 64px;
  }
  background-color: #e4261b;
  color: #fff;
  min-width: 75px;
  max-width: 75px;
  padding: 4px;
  border-radius: 14px;
}

footer .liste .client_file .description,
footer .liste .devis_facture .description {
  width: 30%;
  text-align: left;
}

footer .liste .devis_facture,
footer .liste .client_file {
  @media only screen and (max-width: 900px) {
    padding: 8px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  background-color: #fff;
  color: #28367a;
  margin-bottom: 6px;
}

footer .liste .devis_facture:hover,
footer .liste .client_file:hover {
  background-color: #eef1fd;
}

footer .liste .devis_facture .info,
footer .liste .client_file .info {
  text-align: center !important;
}

footer .liste .devis_facture .info h2,
footer .liste .client_file .info h2,
footer .liste .devis_facture .info p,
footer .liste .client_file .info p {
  margin: 0;
}

footer .liste .devis_facture .info h2,
footer .liste .client_file .info h2 {
  @media only screen and (max-width: 900px) {
    font-size: 12px;
  }
  font-size: 14px;
  font-weight: 800;
  color: #000;
}

footer .liste .devis_facture .info p,
footer .liste .client_file .info p {
  font-size: 12px;
  color: #515151;
}

footer .liste .devis_facture .action,
footer .liste .client_file .action {
  @media only screen and (max-width: 900px) {
    width: 25%;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 15%;
}

footer .liste .devis_facture .action div,
footer .liste .client_file .action div {
  @media only screen and (max-width: 900px) {
    font-size: 11px;
  }
  font-size: 12px;
  padding: 6px;
  text-align: center;
  background-color: #f8f9fa;
  border-radius: 5px;
  cursor: pointer;
}

.custom-placeholder::placeholder {
  font-size: 13px;
}
.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-input {
  box-shadow: none;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>
