<template>
  <div class="">
    
    <div class="row mt-4">
      <!--section client-->

      <div class="col-md-6 col-lg-6 col-sm-12">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>
        <form v-else @submit.prevent="addCLI">
          <div class="background-white mt-3">
            <div class="title">
              <div class="position">{{ $t("CLIENT") }}</div>
            </div>
          </div>
          <div class="center borderPointier mt-3 p-3">
            <ClientStep
            @error-response="handleErrorResponse" 
              :clientParentData="clientData"
              :idLogementData="logementData.id"
              @update-client="handleClientUpdate"
              :logementValue="logementData"
              :menageValue="menageData"
              ref="creeClient"
            />
          </div>
        </form>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12">
        <form>
          <div>
            <v-container v-if="progress" type="image" class="loding">
              <v-skeleton-loader type="image"></v-skeleton-loader>
            </v-container>
            <!--section logement-->
            <div v-else class="borderPointier mt-3 pt-3 px-3">
              <div class="background-white">
                <div class="title">
                  <div class="position">{{ $t("HOUSING") }}</div>
                </div>
              </div>

              <LogementStep
                :logementParentData="logementData"
                :clientId="clientData?.logements"
                @update-logement="handleLogementUpdate"
              />
            </div>

            <div>
              <v-container v-if="progress" type="image" class="loding">
                <v-skeleton-loader type="image"></v-skeleton-loader>
              </v-container>
              <!--section logement-->
              <div v-else class="borderPointier mt-3 pt-3 px-3">
                <div class="background-white">
                  <div class="title">
                    <div class="position">{{ $t("Menage") }}</div>
                  </div>
                </div>

                <MenagesStep
                  :menageParentData="menageData"
                  @update-menage="handleMenageUpdate"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>

      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
        {{ $t("NEXT") }}
        <div class="loading ml-2" v-if="isLoading">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
    <Snackbar ref="snackbar" class="mt-5" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Snackbar from "@/components/ui/errorSnackbar.vue";
import ClientStep from "../client/ClientStep.vue";
import LogementStep from "../logement/LogementStep.vue";
import MenagesStep from "../menages/MenagesStep.vue";
export default {
  components: {
    ClientStep,
    Snackbar,
    LogementStep,
    MenagesStep,
  },
  props: {
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emittedResponse: null,
      cl:null,
      logementData: null,
      menageData: null,
      clientData: null,
      clientAdd: false,
      logementAdd: false,
      isLoading: false,
    };
  },
  created() {
    this.logementData = this.initializeLogementData();
    this.clientData = this.initializeClientData();
    this.menageData = this.initializeMenageData();

    this.clientAdd = this.getdevisMonoFiche.data.devis.client === null;
    this.logementAdd = this.getdevisMonoFiche.data.devis.logement === null;
  },
  computed: {
    ...mapGetters(["getdevisMonoFiche", "getOnlineUser"]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
  },
  watch: {
    clientData: {
      handler(newValue) {
        if (newValue.menage) {
          this.menageData = {
            commune: newValue.menage.commune || null,
            personnes: newValue.menage.personnes || null,
            revenu: newValue.menage.revenu || null,
          };
        } else {
          this.menageData = {
            commune: null,
            personnes: null,
            revenu: null,
          };
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions([
      "editLogement",
      "editMenage",
      "edit_client",
      "storeMenage",
      "storeLogement",
      "all_clients",
      "pays",
      "store_clients",
      "client",
      "store_client",
      "all_countries",

      "setDevisMonoFiche",
    ]),
  
    handleErrorResponse(response) {
      this.emittedResponse = response; // Stocke la valeur dans une propriété locale
      console.log("Réponse reçue :", response); // Affiche dans la console pour vérifier
    },
    initializeLogementData() {
      const logement = this.getdevisMonoFiche?.data?.devis?.logement || {};
      return {
        id: logement.id,
        type_energie_equipement_deposee:
          logement?.type_energie_equipement_deposee || null,
        equipement_deposee: logement?.equipement_deposee || null,
        residence_principale_proprietaire:
          logement?.residence_principale_proprietaire || false,
        proprietaire_occupant: logement?.proprietaire_occupant || false,
        type_chauffage: logement.type_chauffage || null,
        type: logement.type || null,
        periode_de_construction: logement.periode_de_construction || "",
        surface: logement.surface || "",
        parcelle_cadastrale: logement.parcelle_cadastrale || "",
        adresse: logement.adresse || "",
      };
    },
    initializeClientData() {
      const client = this.getdevisMonoFiche?.data?.devis.client || {};
      return {
        nom: client?.nom || "",
        prenom: client?.prenom || "",
        rue: client?.rue || "",
        zone: client?.zone || null,
        cp: client?.cp || "",
        ville: client?.ville || "",
        id: client.id || null,
        precarite: client?.precarite || null,
        type: client?.type || "type.particulier",
        email: client?.email || null,
        pays: client?.pays || null,
        phone: client?.phone || null,
        company_phone: client?.company_phone,

        company_phone_iso_code: client?.company_tel?.phone_iso_code || null,

        tel: {
          phone_number: client.phone?.phone_number || null,
          phone_iso_code: client.phone?.iso_code || null,
        },
        company_tel: {
          phone_number: client.company_phone?.phone_number || null,
          phone_iso_code: client.company_phone?.iso_code || null,
        },

        company: client.company || null,
        company_email: client.company_email || null,
        revenu_avis_imposition: client.revenu_avis_imposition || 0,
        SWIFT_BIC: client.SWIFT_BIC || null,
        forme_juridique: client.forme_juridique || null,
        ape: client.ape || null,
        naf: client.naf || null,
        IBAN: client.IBAN || null,
        titulaire_compte: client.titulaire_compte || null,
        poste: client.poste || null,
        commentaire: client.commentaire || null,
        logements: client.logements || [],
        menage: client.menage || [],
      };
    },

    initializeMenageData() {
      const menage = this.getdevisMonoFiche?.data?.devis?.client?.menage || {};
      return {
        revenu: menage.revenu || null,
        personnes: menage.personnes || null,
        commune: menage.commune || "",
      };
    },
    updateMenageData() {
      if (this.clientData?.menage) {
        this.menage = {
          revenu: this.clientData.menage.revenu || null,
          personnes: this.clientData.menage.personnes || null,
          commune: this.clientData.menage.commune || "",
        };
      }
    },
    async edit() {
      if (this.clientAdd) {
      await this.$refs.creeClient.addCLI();
      
        // await this.addCLI();
        await this.all_clients({
          search: "",
          page: this.page,
          per_page: 100000,
          entreprise_id: this.getdevisMonoFiche.data.devis.entreprise.id,
        });
        if (!this.getdevisMonoFiche.data.devis.client_id) {
          this.$refs.snackbar.showSnackbar("Tous_obligatoires");
          this.isLoading = false;
          return;
        }
        const client = await this.client(
          this.getdevisMonoFiche.data.devis.client_id
        );

        const log = {
          ...this.logementData,
          client_id: client?.data.id,
          id: this.getdevisMonoFiche.data.devis.logement_id,
        };

        await this.editLogement(log);
        this.getdevisMonoFiche.data.devis.logement = {
          id: this.logementData.id,
          adresse: this.logementData.adresse,
          type: this.logementData.type,
          proprietaire_occupant: this.logementData.proprietaire_occupant,
          residence_principale_proprietaire:
            this.logementData.residence_principale_proprietaire,
          equipement_deposee: this.logementData.equipement_deposee,
          type_energie_equipement_deposee:
            this.logementData.type_energie_equipement_deposee,
          periode_de_construction: this.logementData.periode_de_construction,
          surface: this.logementData.surface,
          parcelle_cadastrale: this.logementData.parcelle_cadastrale,
          type_chauffage: this.logementData.type_chauffage,
        };
        this.getdevisMonoFiche.data.devis.client = {
          id: this.getdevisMonoFiche.data.devis.client_id,
          precarite: client.data.precarite,
          pays: client.data.pays,
          nom: client.data.nom,
          prenom: client.data.prenom,
          rue: client.data.rue,
          zone: client.data.zone,
          type: client.data.type,
          ville: client.data.ville,
          tel: client.data.tel,
          phone: client.data.phone,
          company_phone: client.data.company_phone,
          cp: client.data.cp,
          email: client.data.email,
          company: client.data.company,
          company_email: client.data.company_email,
          SWIFT_BIC: client.data.SWIFT_BIC,
          forme_juridique: client.data.forme_juridique,
          naf: client.data.naf,
          ape: client.data.ape,
          revenu_avis_imposition: client.data.revenu_avis_imposition,
          IBAN: client.data.IBAN,
          titulaire_compte: client.data.titulaire_compte,
          poste: client.data.poste,
          commentaire: client.data.commentaire,
          company_tel: client.data.company_tel,
          logements: client.data.logements,
          menage: {
            id: client.data.menage.id,
            revenu: client.data.menage.revenu,
            personnes: client.data.menage.personnes,
            commune: client.data.menage.commune,
          },
        };
        this.setDevisMonoFiche(this.getdevisMonoFiche);
      } else {
        if (this.clientData.id === null) {
          this.$refs.snackbar.showSnackbar(
            "Vérifiez vos données ou sélectionnez un client existant"
          );
          this.isLoading = false;
          return;
        }
        if (
          this.getdevisMonoFiche.data.devis?.client &&
          this.getdevisMonoFiche.data.devis?.client?.id !== this.clientData.id
        ) {
          if (!this.clientData) {
            return;
          }
          this.getdevisMonoFiche.data.devis.client_id = this.clientData.id;
          this.getdevisMonoFiche.data.devis.same_travaux_address === 0;
          const client = {
            ...this.clientData,
            id: this.clientData.id,
            revenu_avis_imposition: this.clientData.revenu_avis_imposition,
            phone_iso_code: this.clientData.tel.iso_code,
            company_phone_iso_code:
              this.clientData?.company_tel?.phone_iso_code,
          };
          if (this.clientData.type === "type.particulier") {
            client.phone_number = this.clientData.tel.phone_number;
          } else {
            const phone = this.cleanPhoneNumber(
              this.clientData.tel.phone_number
            );
            client.phone_number = this.removeSpaces(phone);

            const company_phone = this.cleanPhoneNumber(
              this.clientData?.company_tel?.phone_number
            );
            client.company_phone_number = this.removeSpaces(company_phone);
          }
          client.revenu_avis_imposition =
            this.clientData.revenu_avis_imposition;
         console.log('aa')
          await this.edit_client({ data: client });
          const resclient = await this.client(this.clientData?.id);
          this.getdevisMonoFiche.data.devis.same_travaux_address = 0;

          const loges = { ...this.logementData, client_id: this.clientData.id };
          let menageresult = this.clientData.menage;
          let logementresult;
          if (
            !this.logementData.client_id !=
            this.getdevisMonoFiche.data.devis.client.id
          ) {
            if (!this.idLogementData) {
              logementresult = await this.storeLogement(loges);
            } else {
              const logemen = {
                ...this.logementData,
                id: this.idLogementData,
              };

              await this.editLogement(logemen);
            }
          } else {
            const logemen = {
              ...this.logementData,
              id: this.logementValue.id,
            };

            await this.editLogement(logemen);
          }
          if (!this.clientData.menage) {
            const menage = {
              ...this.menageData,
              client_id: this.clientData.id,
            };
            menageresult = await this.storeMenage(menage);
          }
          if (this.clientData.menage) {
            const menage = {
              ...this.menageData,
              client_id: this.clientData.id,
              id: this.clientData.menage.id,
            };
            await this.editMenage(menage);
          }

          this.getdevisMonoFiche.data.devis.logement = {
            id: logementresult.data.id,
            adresse: this.logementData.adresse,
            proprietaire_occupant: this.logementData.proprietaire_occupant,
            residence_principale_proprietaire:
              this.logementData.residence_principale_proprietaire,
            equipement_deposee: this.logementData.equipement_deposee,
            type_energie_equipement_deposee:
              this.logementData.type_energie_equipement_deposee,
            type: this.logementData.type,
            periode_de_construction: this.logementData.periode_de_construction,
            surface: this.logementData.surface,
            parcelle_cadastrale: this.logementData.parcelle_cadastrale,
            type_chauffage: this.logementData.type_chauffage,
          };
          this.getdevisMonoFiche.data.devis.logement_id =
            logementresult.data.id;
          this.getdevisMonoFiche.data.devis.client = {
            id: this.clientData.id,

            precarite: this.clientData?.precarite,
            pays: this.clientData?.pays,
            nom: this.clientData?.nom,
            prenom: this.clientData?.prenom,
            rue: this.clientData?.rue,
            zone: this.clientData?.zone,
            type: this.clientData?.type,

            ville: this.clientData?.ville,
            phone: resclient?.data.phone,
            company_phone: resclient?.data.company_phone,
            cp: this.clientData?.cp,
            email: this.clientData?.email,

            company: this.clientData?.company,
            company_email: this.clientData?.company_email,
            SWIFT_BIC: this.clientData?.SWIFT_BIC,
            forme_juridique: this.clientData?.forme_juridique,
            ape: this.clientData?.ape,
            naf: this.clientData?.naf,
            revenu_avis_imposition: this.clientData?.revenu_avis_imposition,
            IBAN: this.clientData?.IBAN,
            titulaire_compte: this.clientData?.titulaire_compte,
            poste: this.clientData?.poste,
            commentaire: this.clientData?.commentaire,
            company_tel: resclient?.data.company_tel,
            logements: this.clientData?.logements,
            menage: {
              id: menageresult.id,
              commune: this.menageData.commune,
              personnes: this.menageData.personnes,
              revenu: this.menageData.reven,
            },
          };

          this.setDevisMonoFiche(this.getdevisMonoFiche);
        } else {
          const client = {
            ...this.clientData,
            id: this.getdevisMonoFiche.data.devis.client.id,
            revenu_avis_imposition: this.clientData.revenu_avis_imposition,
            phone_iso_code: this.clientData.tel.iso_code,
            company_phone_iso_code:
              this.clientData?.company_tel?.phone_iso_code,
          };
          if (this.clientData.type === "type.particulier") {
            const phone = this.cleanPhoneNumber(
              this.clientData.tel.phone_number
            );
            client.phone_number = this.removeSpaces(phone);
          } else {
            const phone = this.cleanPhoneNumber(
              this.clientData.tel.phone_number
            );
            client.phone_number = this.removeSpaces(phone);

            const company_phone = this.cleanPhoneNumber(
              this.clientData?.company_tel?.phone_number
            );
            client.company_phone_number = this.removeSpaces(company_phone);
          }
          client.revenu_avis_imposition =
            this.clientData.revenu_avis_imposition;
console.log('eeee')
          await this.edit_client({ data: client });
          const resclient = await this.client(this.clientData?.id);

          const menage = {
            ...this.menageData,
            client_id: this.clientData?.id,
            id: this.getdevisMonoFiche.data.devis.client.menage.id,
          };

          if (
            this.logementData.id !=
            this.getdevisMonoFiche.data.devis.logement_id
          ) {
            const log = {
              ...this.logementData,
              id: this.logementData.id,
              client_id: this.clientData?.id,
            };
            if (!log.id) {
              log.id = this.logementData.id;
            }
            await this.editLogement(log);
            this.getdevisMonoFiche.data.devis.logement_id =
              this.logementData.id;
            this.getdevisMonoFiche.data.devis.logement = {
              id: this.logementData.id,
              adresse: this.logementData.adresse,
              type: this.logementData.type,
              proprietaire_occupant: this.logementData.proprietaire_occupant,
              residence_principale_proprietaire:
                this.logementData.residence_principale_proprietaire,
              equipement_deposee: this.logementData.equipement_deposee,
              type_energie_equipement_deposee:
                this.logementData.type_energie_equipement_deposee,
              periode_de_construction:
                this.logementData.periode_de_construction,
              surface: this.logementData.surface,
              parcelle_cadastrale: this.logementData.parcelle_cadastrale,
              type_chauffage: this.logementData.type_chauffage,
            };
          } else {
            const log = {
              ...this.logementData,
              client_id: this.clientData?.id,
              id: this.getdevisMonoFiche.data.devis.logement_id,
            };
            await this.editLogement(log);
            this.getdevisMonoFiche.data.devis.logement = {
              id: this.getdevisMonoFiche.data.devis.logement_id,
              adresse: this.logementData.adresse,
              type: this.logementData.type,
              proprietaire_occupant: this.logementData.proprietaire_occupant,
              residence_principale_proprietaire:
                this.logementData.residence_principale_proprietaire,
              equipement_deposee: this.logementData.equipement_deposee,
              type_energie_equipement_deposee:
                this.logementData.type_energie_equipement_deposee,
              periode_de_construction:
                this.logementData.periode_de_construction,
              surface: this.logementData.surface,
              parcelle_cadastrale: this.logementData.parcelle_cadastrale,
              type_chauffage: this.logementData.type_chauffage,
            };
          }

          await this.editMenage(menage);
          this.getdevisMonoFiche.data.devis.client = {
            id: client.id,
            precarite: this.clientData?.precarite,
            pays: this.clientData?.pays,
            nom: this.clientData?.nom,
            prenom: this.clientData?.prenom,
            rue: this.clientData?.rue,
            zone: this.clientData?.zone,
            type: this.clientData?.type,
            ville: this.clientData?.ville,
            phone: resclient?.data.phone,
            company_phone: resclient?.data.company_phone,
            cp: this.clientData?.cp,
            email: this.clientData?.email,

            company: this.clientData?.company,
            company_email: this.clientData?.company_email,
            SWIFT_BIC: this.clientData?.SWIFT_BIC,
            forme_juridique: this.clientData?.forme_juridique,
            naf: this.clientData?.naf,
            ape: this.clientData?.ape,
            revenu_avis_imposition: this.clientData?.revenu_avis_imposition,
            IBAN: this.clientData?.IBAN,
            titulaire_compte: this.clientData?.titulaire_compte,
            poste: this.clientData?.poste,
            commentaire: this.clientData?.commentaire,
            company_tel: resclient?.data.company_tel,
            logements: this.clientData?.logements,
            menage: {
              id: this.clientData?.menage.id,
              commune: this.menageData.commune,
              personnes: this.menageData.personnes,
              revenu: this.menageData.revenu,
            },
          };

          this.setDevisMonoFiche(this.getdevisMonoFiche);
        }
      }
    },
    cleanPhoneNumber(phoneNumber) {
      return phoneNumber.replace(/^\+\d+\s*/, "");
    },
    removeSpaces(phoneNumber) {
      return phoneNumber.replace(/\s+/g, "");
    },
    async goToNextStep() {
      this.isLoading = true;
      if (this.clientData.pays == null) {
        this.$refs.snackbar.showSnackbar("Tous_obligatoires");
        this.isLoading = false;
      }
      if (
        this.menageData.commune == null ||
        !this.menageData.personnes ||
        !this.menageData.revenu ||
        !this.logementData.adresse ||
        !this.logementData.type
      ) {
        this.$refs.snackbar.showSnackbar("Tous_obligatoires");
        this.isLoading = false;
        this.err = false;
        return;
      }
      await this.edit();
      if(this.emittedResponse==422 ) {
        this.$refs.snackbar.showSnackbar("e-mail existe déjà");
              this.err = false;

              this.isLoading = false;
              return;
      }
      if(this.emittedResponse==409 ) {
        this.$refs.snackbar.showSnackbar("e-mail existe déjà");
              this.err = false;

              this.isLoading = false;
              return;
      }
      // if (this.clientData.id == null ) {
      //   this.$refs.snackbar.showSnackbar(
      //     "Vérifiez vos données ou sélectionnez un client existant"
      //   );
      //   this.isLoading = false;
      //   this.err = false;
      //   return;
      // }
      this.isLoading = false;
      if (
        !this.getdevisMonoFiche.data.devis.client ||
        !this.getdevisMonoFiche.data.devis.logement
      ) {
        this.$refs.snackbar.showSnackbar("Tous_obligatoires");
        return;
      }
      this.$emit("changeStep", 3, true);
    },
    goToPrevStep() {
      this.$emit("changeStepPrev", 1);
    },
    handleLogementUpdate({ field, value }) {
      this.logementData[field] = value;
    },
    handleMenageUpdate({ field, value }) {
      this.menageData[field] = value;
    },
    handleClientUpdate(updatedData) {
      this.clientData = updatedData; // Met à jour l'objet complet

      // this.clientData[field] = value;
    },
  },
};
</script>
<style scoped>
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
</style>
