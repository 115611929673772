<template>
  <form>
    <b-form-group :label="$t('HOUSING')" class="style">
      <multiselect
        v-model="selectedLogement"
        :searchable="true"
        :close-on-select="true"
        :multiple="false"
        :options="clientId"
        label="adresse"
        track-by="id"
        :placeholder="$t('Select')"
        class="form-input custom-placeholder custom-input"
      />
    </b-form-group>
    <b-form-group :label="$t('Adres')" class="mb-4">
      <b-form-input
        type="text"
        :placeholder="$t('Adres')"
        v-model="logementData.adresse"
        @input="updateParent('adresse', logementData.adresse)"
        required
        class="form-input custom-placeholder custom-input"
      ></b-form-input>
    </b-form-group>
   
    <b-form-checkbox 
      :checked="logementData.proprietaire_occupant === 1"
      @change="
        (checked) => updateParent('proprietaire_occupant', checked ? 1 : 0)
      "
      :label="$t('proprietaire_occupant')"
      class="custom-input"
    >
      {{ $t("proprietaire_occupant") }}
    </b-form-checkbox>
    <b-form-checkbox v-if="logementData.proprietaire_occupant"
      :checked="logementData.residence_principale_proprietaire === 1"
      @change="
        (checked) =>
          updateParent('residence_principale_proprietaire', checked ? 1 : 0)
      "
      :label="$t('residence_principale_proprietaire')"
      class="custom-input"
    >
      {{ $t("residence_principale_proprietaire") }}
    </b-form-checkbox>
    <b-form-group :label="$t('TYPE_CHAUFFAGE')" class="mt-2">
      <b-form-select
        class="form-input custom-placeholder custom-input"
        v-model="logementData.type_chauffage"
        @input="updateParent('type_chauffage', logementData.type_chauffage)"
        :placeholder="$t('TYPE_CHAUFFAGE')"
        :options="optionstypechauffage"
        text-field="text"
        value-field="value"
      >
        <template #first>
          <b-form-select-option :value="null" disabled
            >-- {{ $t("TYPE_CHAUFFAGE") }} --</b-form-select-option
          >
        </template>
      </b-form-select>
    </b-form-group>
    <b-form-group :label="$t('periode_de_construction')">
      <b-form-select
        :placeholder="$t('periode_de_construction')"
        class="form-input custom-placeholder custom-input"
        v-model="logementData.periode_de_construction"
        @input="
          updateParent(
            'periode_de_construction',
            logementData.periode_de_construction
          )
        "
        :options="optionsperiode_de_construction"
        text-field="text"
        value-field="value"
      >
        <template #first>
          <b-form-select-option :value="null" disabled
            >-- {{ $t("periode_de_construction") }} --</b-form-select-option
          >
        </template>
      </b-form-select>
    </b-form-group>
    <b-row>
      <b-col md="6">
        <b-form-group :label="$t('TYPE_LOGEMENT')">
          <b-form-select
            :placeholder="$t('TYPE_LOGEMENT')"
            class="form-input custom-placeholder custom-input"
            v-model="logementData.type"
            @input="updateParent('type', logementData.type)"
            :options="optionstypelogement"
            text-field="text"
            value-field="value"
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- {{ $t("TYPE_LOGEMENT") }} --</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group :label="$t('SURFACE')">
          <b-form-input
            type="number"
            min="0"
            @paste="(event) => onPaste(event, 'logementData.surface')"
            @keydown="(event) => onKeyDownFloat(event)"
            :placeholder="$t('SURFACE')"
            v-model="logementData.surface"
            @input="updateParent('surface', logementData.surface)"
            required
            class="form-input custom-placeholder custom-input"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group :label="$t('CADASTRAL_PARCEL')" class="mb-4">
      <b-form-input
        type="text"
        :placeholder="$t('CADASTRAL_PARCEL')"
        v-model="logementData.parcelle_cadastrale"
        @input="
          updateParent('parcelle_cadastrale', logementData.parcelle_cadastrale)
        "
        required
        class="form-input custom-placeholder custom-input"
      ></b-form-input>
    </b-form-group>
    <b-form-group :label="$t('equipement')">
      <b-form-select
        :placeholder="$t('equipement')"
        class="form-input custom-placeholder custom-input"
        v-model="logementData.equipement_deposee"
        :options="optionstypeEquipement_deposee"
        @input="
          updateParent('equipement_deposee', logementData.equipement_deposee)
        "
        text-field="text"
        value-field="value"
      >
        <template #first>
          <b-form-select-option :value="null" disabled
            >-- {{ $t("equipement") }} --</b-form-select-option
          >
        </template>
      </b-form-select>
    </b-form-group>
    <b-form-group :label="$t('Type_de_combustible')">
      <b-form-select
        :placeholder="$t('Type_de_combustible')"
        class="form-input custom-placeholder custom-input"
        v-model="logementData.type_energie_equipement_deposee"
        @input="
          updateParent(
            'type_energie_equipement_deposee',
            logementData.type_energie_equipement_deposee
          )
        "
        :options="optionsType_de_combustible"
        text-field="text"
        value-field="value"
      >
        <template #first>
          <b-form-select-option :value="null" disabled
            >-- {{ $t("Type_de_combustible") }} --</b-form-select-option
          >
        </template>
      </b-form-select>
    </b-form-group>
  </form>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {
  preventExceedingLength,
  handlePaste,
  preventExceedingLengthFloat,
} from "@/utils/inputUtils";
export default {
  props: {
    clientId: {
      type: Number,
      required: true,
    },
    logementParentData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: false,
      inputSearchValue: "",
      logementData: { ...this.logementParentData },
      optionstypeEquipement_deposee: [
        { value: "Chaudière", text: this.$t("chaudière") },
        { value: "Cheminée", text: this.$t("cheminée") },
        { value: "Pôle", text: this.$t("pôle") },
      ],
      optionsType_de_combustible: [
        { value: "Granulé", text: this.$t("granulé") },
        { value: "Fioul", text: this.$t("Fuel") },
        { value: "Gaz", text: this.$t("Gaz") },
      ],
      optionstypechauffage: [
        { value: "Combustible/bois", text: this.$t("Combustible/Bois") },
        { value: "Electrique", text: this.$t("Electrique") },
        { value: "Gaz", text: this.$t("Gaz") },
        { value: "GPL", text: this.$t("GPL") },
        { value: "Fioul", text: this.$t("Fuel") },
        { value: "Bois", text: this.$t("Bois") },
        { value: "ENR/PAC", text: "ENR/PAC" },
      ],
      optionstypelogement: [
        { value: "Maison individuelle", text: this.$t("Maison individuelle") },
        { value: "Appartement", text: this.$t("Appartement") },
        // { value: "Collectif", text: this.$t("Collectif") },
      ],
      optionsperiode_de_construction: [
        { value: "moins de 2 ans", text: this.$t("Moins_de_2_ans") },
        {
          value: "de 2 à 10 ans",
          text: this.$t("De 2 à 10 ans"),
        },
        { value: "de 10 à 15 ans", text: this.$t("De 10 à 15 ans") },
        { value: "au moins 15 ans", text: this.$t("Plus_de_15_ans") },
      ],
    };
  },
  computed: {
    ...mapGetters(["getdevisMonoFiche", "getOnlineUser"]),
    selectedLogement: {
      get() {
        if (
          typeof this.logementData === "object" &&
          this.logementData !== null
        ) {
          return this.logementData;
        } else {
          return this.clientId.find(
            (option) => option.id === this.logementData.id
          );
        }
      },
      set(value) {
        if (value) {
          const updatedData = {
            ...value,
            parcelle_cadastrale: value.parcelle_cadastrale || null,
            equipement_deposee: value.equipement_deposee || null,
            type_energie_equipement_deposee:
              value.type_energie_equipement_deposee || null,
            type_chauffage: value.type_chauffage || null,
            type_logement: value.type_logement || null,
            periode_de_construction: value.periode_de_construction || null,
            surface: value.surface || null,
            type: value.type || null,
            adresse: value.adresse || null,
          };

          Object.keys(updatedData).forEach((field) => {
            this.updateParent(field, updatedData[field]);
          });

          this.logementData = updatedData;
          this.selected = true;
        } else {
          const resetData = {
            parcelle_cadastrale: null,
            equipement_deposee: null,
            type_energie_equipement_deposee: null,
            type_chauffage: null,
            type_logement: null,
            periode_de_construction: null,
            surface: null,
            type: null,
            adresse: null,
          };

          Object.keys(resetData).forEach((field) => {
            this.updateParent(field, resetData[field]);
          });

          this.logementData = resetData;
          this.selected = false;
        }
      },
    },
  },
  watch: {
    inputSearchValue(newValue) {
      if (newValue.trim() === "") {
        this.selected = false;
      }
    },
    logementParentData: {
      deep: true,
      handler(newData) {
        this.logementData = { ...newData };
      },
    },
  },
  methods: {
    ...mapActions(["editLogement", "storeLogement"]),
    onKeyDown(event) {
      preventExceedingLength(event);
    },
    updateParent(field, value) {
      this.$emit("update-logement", { field, value });
    },
    onKeyDownFloat(event) {
      preventExceedingLengthFloat(event);
    },
    onPaste(event, fieldName) {
      handlePaste.call(this, event, fieldName);
    },
  },
};
</script>
