<template>
  <form @submit.prevent="addCLI">
    <b-form-group :label="$t('CUSTOMER')" class="style">
   
      <multiselect
        v-model="selectedClient"
        :searchable="true"
        :close-on-select="true"
        :multiple="false"
        :options="getAllclients"
        :custom-label="customLabel"
        label="nom"
        track-by="id"
        :placeholder="$t('Select')"
        class="form-input custom-placeholder custom-input"
      />
    </b-form-group>
    <b-form-group :label="$t('TYPE')+ ' *'">
      <b-form-select
        v-model="clientData.type"
        :options="optionsTypeClient"
        :state="validateState('type')"
        aria-describedby="type-feedback"
        @change="v$.$reset()"
      ></b-form-select>
      <error-handle
        :list="v$.clientData.type.$errors"
        id="type-feedback"
      ></error-handle>
      <div v-if="erreurlist.type" class="error-message">
        <ul v-if="Array.isArray(erreurlist.type)">
          <span v-for="(e, index) in erreurlist.type" :key="index">
            {{ e }}
          </span>
        </ul>
        <span v-else>{{ erreurlist.type }}</span>
      </div>
    </b-form-group>
    <h5 class="titresociete" v-if="clientData.type == 'type.professionnel'">
      {{ $t("COMPANY_INTER") }}
    </h5>

    <div class="fieldset mt-3">
      <div class="legend mb-1">{{ $t("SURNAME") }}</div>
      <b-form-input
        :state="validateState('prenom')+ ' *'"
        oninput="this.value = this.value.toUpperCase()"
        v-model="v$.clientData.prenom.$model"
        @input="updateClientParent('prenom', clientData.prenom)"
        :placeholder="$t('SURNAME')"
        aria-describedby="prenom-feedback"
        class="form-input custom-placeholder custom-input"
      >
      </b-form-input>
      <error-handle
        :list="v$.clientData.prenom.$errors"
        id="prenom-feedback"
      ></error-handle>
      <div v-if="erreurlist.prenom" class="error-message">
        <ul v-if="Array.isArray(erreurlist.prenom)">
          <span v-for="(e, index) in erreurlist.prenom" :key="index">
            {{ e }}
          </span>
        </ul>
        <span v-else>{{ erreurlist.prenom }}</span>
      </div>
    </div>

    <div class="fieldset">
      <div class="legend mb-1">{{ $t("NAME") }} *</div>
      <b-form-input
        v-model="v$.clientData.nom.$model"
        @input="updateClientParent('nom', clientData.nom)"
        :state="validateState('nom')"
        :placeholder="$t('NAME')"
        aria-describedby="nom-feedback"
        oninput="this.value = this.value.toUpperCase()"
        class="form-input custom-placeholder custom-input"
        :class="{ 'form-control': false }"
      >
      </b-form-input>
      <error-handle
        :list="v$.clientData.nom.$errors"
        id="nom-feedback"
      ></error-handle>
      <div v-if="erreurlist.nom" class="error-message">
        <ul v-if="Array.isArray(erreurlist.nom)">
          <span v-for="(e, index) in erreurlist.nom" :key="index">
            {{ e }}
          </span>
        </ul>
        <span v-else>{{ erreurlist.nom }}</span>
      </div>
    </div>

    <b-form-group
      v-if="clientData.type == 'type.professionnel'"
      :label="$t('Poste') + '*'"
    >
      <b-form-input
        v-model="v$.clientData.poste.$model"
        @input="updateClientParent('poste', clientData.poste)"
        oninput="this.value = this.value.toUpperCase()"
        :state="validateState('poste')"
        aria-describedby="poste-feedback"
      ></b-form-input>
      <error-handle
        :list="v$.clientData.poste.$errors"
        id="poste-feedback"
      ></error-handle>
    </b-form-group>

    <b-form-group
      :label="$t('PHONE')+ ' *'"
      @input="updateClientParent('tel', clientData.tel)"
      v-modal="this.clientData.tel"
      label-for="telephone"
      class="mb-1 mt-3"
    >
      <template>
        <phone
          :tel="clientData.tel"
          @phoneNumber="formatNumber($event)"
          class="form-input custom-placeholder custom-input"
        ></phone>
      </template>
      <div v-if="erreurlist.tel" class="error-message">
        <ul v-if="Array.isArray(erreurlist.tel)">
          <span v-for="(e, index) in erreurlist.tel" :key="index">
            {{ e }}
          </span>
        </ul>
        <span v-else>{{ erreurlist.tel }}</span>
      </div>
      <div v-if="v$.clientData.tel.$error" class="error-message">
        {{ $t("REQUIRED_FIELD") }}
      </div>
      <div v-if="clientData.tel && resultValid == false" class="error-message">
        {{ $t("ERROR_PHONE") }}
      </div>
    </b-form-group>

    <b-form-group :label="$t('EMAIL') + '*'" label-for="email">
      <b-form-input
        type="email"
        :state="validateState('email')"
        v-model="v$.clientData.email.$model"
        @input="updateClientParent('email', clientData.email)"
        :placeholder="$t('CLIENT_EMAIL')"
        aria-describedby="email-feedback"
        :disabled="selected"
        class="form-input custom-placeholder custom-input"
      ></b-form-input>
      <error-handle
        :list="v$.clientData.email.$errors"
        id="email-feedback"
      ></error-handle>
      <div v-if="erreurlist.email" class="error-message">
        <ul v-if="Array.isArray(erreurlist.email)">
          <span v-for="(e, index) in erreurlist.email" :key="index">
            {{ e }}
          </span>
        </ul>
        <span v-else>{{ erreurlist.email }}</span>
      </div>
    </b-form-group>
    <div v-if="clientData.type == 'type.particulier'">
      <b-form-group :label="$t('STREET') + '*'" label-for="rue">
        <b-form-input
          v-model="v$.clientData.rue.$model"
          @input="updateClientParent('rue', clientData.rue)"
          :state="validateState('rue')"
          :placeholder="$t('STREET')"
          aria-describedby="rue-feedback"
          class="form-input custom-placeholder custom-input"
        ></b-form-input>
        <error-handle
          :list="v$.clientData.rue.$errors"
          id="rue-feedback"
        ></error-handle>
        <div v-if="erreurlist.rue" class="error-message">
          <ul v-if="Array.isArray(erreurlist.rue)">
            <span v-for="(e, index) in erreurlist.rue" :key="index">
              {{ e }}
            </span>
          </ul>
          <span v-else>{{ erreurlist.rue }}</span>
        </div>
      </b-form-group>
      <div class="row mt-3 adresse">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <div class="legend mb-1">{{ $t("POSTAL_CODE") }} *</div>
          <b-form-input
            type="number"
            @paste="(event) => handlePaste(event, 'clientData.cp')"
            @keydown="(event) => preventExceedingLength(event)"
            v-model="v$.clientData.cp.$model"
            @input="
              updateClientParent('cp', clientData.cp) && validatePostalCode
            "
            :state="validateState('cp')"
            :placeholder="$t('POSTAL_CODE')"
            aria-describedby="cp-feedback"
            maxlength="5"
            max="99999"
            class="form-input custom-placeholder custom-input"
          ></b-form-input>
          <error-handle
            :list="v$.clientData.cp.$errors"
            id="cp-feedback"
          ></error-handle>
          <div v-if="erreurlist.cp" class="error-message">
            <ul v-if="Array.isArray(erreurlist.cp)">
              <span v-for="(e, index) in erreurlist.cp" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.cp }}</span>
          </div>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12">
          <div class="legend mb-1">{{ $t("CITY") }} *</div>
          <b-form-input
            v-model="clientData.ville"
            :placeholder="$t('CITY')"
            aria-describedby="ville-feedback"
            @keydown="preventNumberInput"
            @input="
              clientData.ville =
                clientData.ville.replace(/[^a-zA-ZÀ-ÿ\s'-]/g, '') &&
                updateClientParent('ville', clientData.ville)
            "
            class="form-input custom-placeholder custom-input"
          ></b-form-input>
          <error-handle
            :list="v$.clientData.ville.$errors"
            id="ville-feedback"
          ></error-handle>
          <div v-if="erreurlist.ville" class="error-message">
            <ul v-if="Array.isArray(erreurlist.ville)">
              <span v-for="(e, index) in erreurlist.ville" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.ville }}</span>
          </div>
        </div>
      </div>

      <div class="row mt-3 adresse">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <b-form-group :label="$t('ZONE')+ ' *'">
            <b-form-select
              v-model="clientData.zone"
              @input="updateClientParent('zone', clientData.zone)"
              :options="optionsZone"
              text-field="text"
              value-field="value"
              class="form-input custom-placeholder custom-input"
            >
            </b-form-select>
          </b-form-group>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12">
          <b-form-group :label="$t('COUNTRY')+ ' *'">
            <multiselect
              v-model="clientData.pays"
              @input="updateClientParent('pays', clientData.pays)"
              :placeholder="$t('COUNTRY')"
              :searchable="true"
              :close-on-select="true"
              :multiple="false"
              :options="getPays"
              label="name"
              track-by="id"
              class="form-input custom-placeholder custom-input"
            >
              <template slot="noResult">
                {{ $t("NO_DATA_FOUND") }}
              </template>
              <div v-if="erreurlist.pays" class="error-message">
                <ul v-if="Array.isArray(erreurlist.pays)">
                  <span v-for="(e, index) in erreurlist.pays" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.pays }}</span>
              </div>
              <div v-if="v$.clientData.pays.$error" class="error-message">
                {{ $t("REQUIRED_FIELD") }}
              </div>
            </multiselect>
          </b-form-group>
        </div>
      </div>

      <b-form-group :label="$t('précaire')+ ' *'" class="mt-2">
        <b-form-select
          class="form-input custom-placeholder custom-input"
          v-model="clientData.precarite"
          @input="updateClientParent('precarite', clientData.precarite)"
          :placeholder="$t('précaire')"
          :options="optionsprécaire"
          text-field="text"
          value-field="value"
        >
          <template #first>
            <b-form-select-option :value="null" disabled
              >-- {{ $t("précaire") }} --</b-form-select-option
            >
          </template>
        </b-form-select>
      </b-form-group>
    </div>
    <div v-if="clientData.type == 'type.professionnel'" style="width: 100%">
      <h5 class="titresociete">
        {{ $t("CONTACT_S") }}
      </h5>
      <b-form-group :label="$t('SOCIETY') + '*'">
        <b-form-input
          v-model="v$.clientData.company.$model"
          @input="updateClientParent('company', clientData.company)"
          oninput="this.value = this.value.toUpperCase()"
          :state="validateState('company')"
          aria-describedby="company-feedback"
        ></b-form-input>
        <error-handle
          :list="v$.clientData.company.$errors"
          id="company-feedback"
        ></error-handle>
        <div v-if="erreurlist.company" class="error-message">
          <ul v-if="Array.isArray(erreurlist.company)">
            <span v-for="(e, index) in erreurlist.company" :key="index">
              {{ e }}
            </span>
          </ul>
          <span v-else>{{ erreurlist.company }}</span>
        </div>
      </b-form-group>

      <b-form-group :label="$t('STREET') + '*'" label-for="rue">
        <b-form-input
          v-model="v$.clientData.rue.$model"
          @input="updateClientParent('rue', clientData.rue)"
          :state="validateState('rue')"
          :placeholder="$t('STREET')"
          aria-describedby="rue-feedback"
          class="form-input custom-placeholder custom-input"
        ></b-form-input>
        <error-handle
          :list="v$.clientData.rue.$errors"
          id="rue-feedback"
        ></error-handle>
        <div v-if="erreurlist.rue" class="error-message">
          <ul v-if="Array.isArray(erreurlist.rue)">
            <span v-for="(e, index) in erreurlist.rue" :key="index">
              {{ e }}
            </span>
          </ul>
          <span v-else>{{ erreurlist.rue }}</span>
        </div>
      </b-form-group>
      <b-form-group :label="$t('POSTAL_CODE') + '*'" label-for="cp">
        <b-form-input
          type="number"
          @paste="(event) => onPaste(event, 'clientData.cp')"
          @keydown="(event) => onKeyDown(event)"
          v-model="v$.clientData.cp.$model"
          @input="updateClientParent('cp', clientData.cp) && validatePostalCode"
          :state="validateState('cp')"
          :placeholder="$t('POSTAL_CODE')"
          aria-describedby="cp-feedback"
          maxlength="5"
          max="99999"
          class="form-input custom-placeholder custom-input"
        ></b-form-input>
        <error-handle
          :list="v$.clientData.cp.$errors"
          id="cp-feedback"
        ></error-handle>
        <div v-if="erreurlist.cp" class="error-message">
          <ul v-if="Array.isArray(erreurlist.cp)">
            <span v-for="(e, index) in erreurlist.cp" :key="index">
              {{ e }}
            </span>
          </ul>
          <span v-else>{{ erreurlist.cp }}</span>
        </div>
      </b-form-group>
      <b-form-group :label="$t('CITY') + '*'" label-for="ville">
        <b-form-input
          v-model="clientData.ville"
          :placeholder="$t('CITY')"
          aria-describedby="ville-feedback"
          @keydown="preventNumberInput"
          @input="
            updateClientParent('ville', clientData.ville) &&
              (clientData.ville = clientData.ville.replace(
                /[^a-zA-ZÀ-ÿ\s'-]/g,
                ''
              ))
          "
          class="form-input custom-placeholder custom-input"
        ></b-form-input>
        <error-handle
          :list="v$.clientData.ville.$errors"
          id="ville-feedback"
        ></error-handle>
        <div v-if="erreurlist.ville" class="error-message">
          <ul v-if="Array.isArray(erreurlist.ville)">
            <span v-for="(e, index) in erreurlist.ville" :key="index">
              {{ e }}
            </span>
          </ul>
          <span v-else>{{ erreurlist.ville }}</span>
        </div>
      </b-form-group>
      <b-form-group :label="$t('ZONE')+ ' *'">
        <b-form-select
          v-model="clientData.zone"
          @input="updateClientParent('zone', clientData.zone)"
          :options="optionsZone"
          text-field="text"
          value-field="value"
          class="form-input custom-placeholder custom-input"
        >
        </b-form-select>
      </b-form-group>

      <b-form-group :label="$t('COUNTRY')+ ' *'">
        <multiselect
          v-model="clientData.pays"
          @input="updateClientParent('pays', clientData.pays)"
          :placeholder="$t('COUNTRY')"
          :searchable="true"
          :close-on-select="true"
          :multiple="false"
          :options="getPays"
          label="name"
          track-by="id"
          class="form-input custom-placeholder custom-input"
        >
          <template slot="noResult">
            {{ $t("NO_DATA_FOUND") }}
          </template>
          <div v-if="erreurlist.pays" class="error-message">
            <ul v-if="Array.isArray(erreurlist.pays)">
              <span v-for="(e, index) in erreurlist.pays" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.pays }}</span>
          </div>
          <div v-if="v$.clientData.pays.$error" class="error-message">
            {{ $t("REQUIRED_FIELD") }}
          </div>
        </multiselect>
      </b-form-group>
      <b-form-group
        v-modal="this.clientData.company_tel"
        @input="updateClientParent('company_tel', clientData.company_tel)"
        :label="$t('COMPANY_PHONE') + '*'"
      >
        <template>
          <phone
            :tel="clientData.company_tel"
            @phoneNumber="formatNumberE($event)"
            class="form-input custom-placeholder custom-input"
          ></phone>
        </template>
        <div v-if="erreurlist.company_tel" class="error-message">
          <ul v-if="Array.isArray(erreurlist.company_tel)">
            <span v-for="(e, index) in erreurlist.company_tel" :key="index">
              {{ e }}
            </span>
          </ul>
          <span v-else>{{ erreurlist.company_tel }}</span>
        </div>

        <div
          v-if="clientData.company_tel && !phoneValid"
          class="error-message"
        ></div>
      </b-form-group>

      <b-form-group :label="$t('COMPANY_EMAIL') + '*'" label-for="email">
        <b-form-input
          v-model="v$.clientData.company_email.$model"
          @input="updateClientParent('company_email', clientData.company_email)"
          required
          type="email"
          :state="validateState('company_email')"
          :disabled="selected"
          aria-describedby="company_email-feedback"
        ></b-form-input>
        <error-handle
          :list="v$.clientData.company_email.$errors"
          id="company_email-feedback"
        ></error-handle>
        <div v-if="erreurlist.company_email" class="error-message">
          <ul v-if="Array.isArray(erreurlist.company_email)">
            <span v-for="(e, index) in erreurlist.company_email" :key="index">
              {{ e }}
            </span>
          </ul>
          <span v-else>{{ erreurlist.company_email }}</span>
        </div>
      </b-form-group>

      <h5 class="titresociete">{{ $t("COMPANY_BANK") }}</h5>

      <b-form-group :label="$t('ACCOUNT_OWNER')+ ' *'">
        <b-form-input
          v-model="v$.clientData.titulaire_compte.$model"
          @input="
            updateClientParent('titulaire_compte', clientData.titulaire_compte)
          "
          :state="validateState('titulaire_compte')"
          aria-describedby="titulaire_compte-feedback"
        ></b-form-input>
        <error-handle
          :list="v$.clientData.titulaire_compte.$errors"
          id="titulaire_compte-feedback"
        ></error-handle>
        <div v-if="erreurlist.titulaire_compte" class="error-message">
          <ul v-if="Array.isArray(erreurlist.titulaire_compte)">
            <span
              v-for="(e, index) in erreurlist.titulaire_compte"
              :key="index"
            >
              {{ e }}
            </span>
          </ul>
          <span v-else>{{ erreurlist.titulaire_compte }}</span>
        </div>
      </b-form-group>

      <b-form-group label="IBAN *" label-for="IBAN">
        <b-form-input
          v-model="v$.clientData.IBAN.$model"
          @input="updateClientParent('IBAN', clientData.IBAN)"
          :state="validateState('IBAN')"
          aria-describedby="IBAN-feedback"
        ></b-form-input>
        <error-handle
          :list="v$.clientData.IBAN.$errors"
          id="IBAN-feedback"
        ></error-handle>
        <div v-if="erreurlist.IBAN" class="error-message">
          <ul v-if="Array.isArray(erreurlist.IBAN)">
            <span v-for="(e, index) in erreurlist.IBAN" :key="index">
              {{ e }}
            </span>
          </ul>
          <span v-else>{{ erreurlist.IBAN }}</span>
        </div>
      </b-form-group>

      <b-form-group label="SWIFT_BIC *" label-for="SWIFT_BIC">
        <b-form-input
          v-model="v$.clientData.SWIFT_BIC.$model"
          @input="updateClientParent('SWIFT_BIC', clientData.SWIFT_BIC)"
          :state="validateState('SWIFT_BIC')"
          aria-describedby="SWIFT_BIC-feedback"
          oninput="this.value = this.value.toUpperCase()"
        ></b-form-input>
        <error-handle
          :list="v$.clientData.SWIFT_BIC.$errors"
          id="SWIFT_BIC-feedback"
        ></error-handle>
        <div v-if="erreurlist.SWIFT_BIC" class="error-message">
          <ul v-if="Array.isArray(erreurlist.SWIFT_BIC)">
            <span v-for="(e, index) in erreurlist.SWIFT_BIC" :key="index">
              {{ e }}
            </span>
          </ul>
          <span v-else>{{ erreurlist.SWIFT_BIC }}</span>
        </div>
      </b-form-group>

      <b-form-group :label="$t('forme_juridique')+ ' *'" label-for="forme_juridique">
        <b-form-input
          v-model="v$.clientData.forme_juridique.$model"
          @input="
            updateClientParent('forme_juridique', clientData.forme_juridique)
          "
          :state="validateState('forme_juridique')"
          aria-describedby="forme_juridique-feedback"
        ></b-form-input>
        <error-handle
          :list="v$.clientData.forme_juridique.$errors"
          id="forme_juridique-feedback"
        ></error-handle>
        <div v-if="erreurlist.forme_juridique" class="error-message">
          <ul v-if="Array.isArray(erreurlist.forme_juridique)">
            <span v-for="(e, index) in erreurlist.forme_juridique" :key="index">
              {{ e }}
            </span>
          </ul>
          <span v-else>{{ erreurlist.forme_juridique }}</span>
        </div>
      </b-form-group>
      <b-form-group :label="$t('ape')+ ' *'" label-for="ape">
        <b-form-input
          v-model="v$.clientData.ape.$model"
          @input="updateClientParent('ape', clientData.ape)"
          :state="validateState('ape')"
          aria-describedby="ape-feedback"
        ></b-form-input>
        <error-handle
          :list="v$.clientData.ape.$errors"
          id="ape-feedback"
        ></error-handle>
        <div v-if="erreurlist.ape" class="error-message">
          <ul v-if="Array.isArray(erreurlist.ape)">
            <span v-for="(e, index) in erreurlist.ape" :key="index">
              {{ e }}
            </span>
          </ul>
          <span v-else>{{ erreurlist.ape }}</span>
        </div>
      </b-form-group>
      <b-form-group :label="$t('naf')+ ' *'" label-for="naf">
        <b-form-input
          v-model="v$.clientData.naf.$model"
          @input="updateClientParent('naf', clientData.naf)"
          :state="validateState('naf')"
          aria-describedby="naf-feedback"
        ></b-form-input>
        <error-handle
          :list="v$.clientData.naf.$errors"
          id="ape-feedback"
        ></error-handle>
        <div v-if="erreurlist.naf" class="error-message">
          <ul v-if="Array.isArray(erreurlist.naf)">
            <span v-for="(e, index) in erreurlist.naf" :key="index">
              {{ e }}
            </span>
          </ul>
          <span v-else>{{ erreurlist.naf }}</span>
        </div>
      </b-form-group>
      <hr />

      <b-form-group :label="$t('COMMENTAIRE')" label-for="commentaire">
        <b-form-textarea
          v-model="clientData.commentaire"
          @input="updateClientParent('commentaire', clientData.commentaire)"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <div v-if="erreurlist.commentaire" class="error-message">
          <ul v-if="Array.isArray(erreurlist.commentaire)">
            <span v-for="(e, index) in erreurlist.commentaire" :key="index">
              {{ e }}
            </span>
          </ul>
          <span v-else>{{ erreurlist.commentaire }}</span>
        </div>
      </b-form-group>
    </div>

    <b-form-group :label="$t('revenu_impo')+ ' *'" label-for="revenu_avis_imposition">
      <b-form-input
        v-model="clientData.revenu_avis_imposition"
        @input="
          updateClientParent(
            'revenu_avis_imposition',
            clientData.revenu_avis_imposition
          )
        "
        @paste="(event) => onPaste(event, 'clientData.revenu_avis_imposition')"
        @keydown="(event) => onKeyDownFloat(event)"
        type="text"
        min="0"
        aria-describedby="revenu_avis_imposition-feedback"
        oninput="this.value = this.value.toUpperCase()"
      ></b-form-input>
    </b-form-group>
  </form>
</template>
<script>
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import phone from "@/components/ui/phone.vue";
import { useVuelidate } from "@vuelidate/core";
import { mapGetters, mapActions } from "vuex";

import {
  required,
  email,
  maxLength,
  minLength,
  requiredIf,
  alphaNum,
  numeric,
} from "@vuelidate/validators";
import {
  preventExceedingLength,
  handlePaste,
  preventExceedingLengthFloat,
} from "@/utils/inputUtils";
import errorHandle from "@/components/ui/errorHandle.vue";
export default {
  props: {
    idLogementData: {
      type: Number,
      required: false,
    },
    menageValue: {
      type: Object,
      required: false,
    },
    logementValue: {
      type: Object,
      required: false,
    },
    clientParentData: {
      type: Object,
      required: true,
    },
  },
  components: {
    phone,
    errorHandle,
 

  },
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  data() {
    return {
      menageData: null,
      clientData: { ...this.clientParentData },
      erreurlist: {
        nom: null,
        prenom: null,
        email: null,
        rue: null,
        cp: null,
        ville: null,
        tel: null,
        type: null,
        zone: null,
        pays: null,
        SWIFT_BIC: null,
        forme_juridique: null,
        ape: null,
        naf: null,

        IBAN: null,
        titulaire_compte: null,
        commentaire: null,
        company: null,
        company_email: null,
        company_tel: null,

        precarite: null,
        type_chauffage: null,
        type_logement: null,
      },
      phoneValid: null,
      resultValid: null,
      errorS: null,
      selected: false,
      inputSearchValue: "",
      optionsTypeClient: [
        { value: "type.particulier", text: this.$t("PARTICULAR") },
        { value: "type.professionnel", text: this.$t("PROFESSIONAL") },
      ],
      optionsZone: [
        { value: "h1", text: "H1" },
        { value: "h2", text: "H2" },
        { value: "h3", text: "H3" },
      ],
      optionsprécaire: [
        { value: "Modeste", text: this.$t("Modeste") },
        { value: "Intermédiaires", text: this.$t("Intermédiaires") },
        { value: "Tres modeste", text: this.$t("Tmodeste") },
        { value: "Supérieurs", text: this.$t("Supérieurs") },
      ],
      search: "",
      disabled: false,
      clientAdd: false,
    };
  },
  watch: {
    inputSearchValue(newValue) {
      if (newValue.trim() === "") {
        this.selected = false;
      }
    },
    clientParentData: {
      deep: true,
      handler(newData) {
        this.clientData = { ...newData };
      },
    },
  },
  created() {
    var lang;
    this.clientAdd = this.getdevisMonoFiche.data.devis.client === null;

    switch (this.getSelectedLang.iso_code) {
      case "fr":
        lang = "fr";
        break;
      case "prt":
        lang = "pt";
        break;
      case "IT":
        lang = "it";
        break;
      case "ar":
        lang = "fa";
        break;

      case "Gr":
        lang = "de";
        break;
      default:
        lang = " ";
    }
    this.pays(lang);
    this.all_countries({ page: this.page, perPage: 100000 });

    this.all_clients({
      search: "",
      page: this.page,
      per_page: 100000,
      entreprise_id: this.getdevisMonoFiche.data.devis.entreprise.id,
    });
  },
  computed: {
    ...mapGetters([
      "getdevisType",
      "getPays",
      "getSelectedLang",
      "getAllcountries",
      "getAllclients",
      "getdevisMonoFiche",
      "getOnlineUser",
    ]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    selectedClient: {
      get() {
        if (typeof this.clientData === "object" && this.clientData !== null) {
          return this.clientData;
        } else {
          return this.getAllclients.find(
            (option) => option.id === this.clientData
          );
        }
      },
      set(value) {
        if (value) {
          const updatedData = {
            ...value,
            tel: {
              phone_number: value.phone?.phone_number || "",
              phone_iso_code: value.phone?.iso_code || "",
            },
            company_tel: {
              phone_number: value.company_phone?.phone_number || "",
              phone_iso_code: value.company_phone?.iso_code || "",
            },

            precarite: value.precarite || null,
          };

          for (const [field, fieldValue] of Object.entries(updatedData)) {
            this.updateClientParent(field, fieldValue);
            this.$set(this.clientData, field, fieldValue);
          }
          this.logementValue = null;
          this.getdevisMonoFiche.data.devis.logement_id = null;
          this.getdevisMonoFiche.data.devis.logement = {};
          this.selected = true;
        } else {
          const resetData = {
            nom: "",
            prenom: "",
            rue: "",
            cp: "",
            ville: "",
            id: null,
            zone: null,
            precarite: null,
            type: "type.particulier",
            email: "",
            pays: "",
            phone: "",
            tel: {
              phone_number: "",
              phone_iso_code: "",
            },
            menage: null,
          };
          for (const [field, fieldValue] of Object.entries(resetData)) {
            this.updateClientParent(field, fieldValue);
            this.$set(this.clientData, field, fieldValue);
          }

          this.logementValue = null;
          this.getdevisMonoFiche.data.devis.logement_id = null;
          this.getdevisMonoFiche.data.devis.logement = {};
          this.selected = false;
        }
      },
    },
  },

  validations() {
    return {
      clientData: {
        type: { required },
        nom: { required },
        prenom: { required },
        email: { required, email, maxLength: maxLength(50) },
        rue: { required, maxLength: maxLength(100) },
        cp: {
          required,
          numeric,
          minLength: minLength(4),
          maxLength: maxLength(5),
        },
        ville: { required, maxLength: maxLength(100) },
        tel: {
          phone_number: { required },
        },
        pays: { required },

        company: {
          requiredIf: requiredIf(this.isProfessionel),
          maxLength: maxLength(100),
        },
        company_email: {
          email,
          requiredIf: requiredIf(this.isProfessionel),
          maxLength: maxLength(50),
        },
        company_tel: {
          phone_number: { requiredIf: requiredIf(this.isProfessionel) },
        },
        poste: {
          requiredIf: requiredIf(this.isProfessionel),
          maxLength: maxLength(40),
        },

        forme_juridique: {
          requiredIf: requiredIf(this.isProfessionel),
        },
        ape: {
          requiredIf: requiredIf(this.isProfessionel),
        },
        naf: {
          requiredIf: requiredIf(this.isProfessionel),
        },
        SWIFT_BIC: {
          minLength: minLength(8),
          maxLength: maxLength(11),
          alphaNum,
        },
        IBAN: { maxLength: maxLength(34), alphaNum },
        titulaire_compte: { maxLength: maxLength(40) },
      },
    };
  },
  methods: {
    ...mapActions([
      "storeMenage",
      "editMenage",
      "edit_client",
      "all_clients",
      "pays",
      "storeLogement",
      "all_clients",
      "store_clients",
      "client",
      "store_client",
      "all_countries",
      "setDevisMonoFiche",
    ]),
    customLabel(client) {
      return `${client.nom} ${client.prenom}`;
    },
    onKeyDown(event) {
      preventExceedingLength(event);
    },
    updateClientParent(field, value) {
      this.$emit("update-client", { ...this.clientData, [field]: value });
    },

    onKeyDownFloat(event) {
      preventExceedingLengthFloat(event);
    },
    onPaste(event, fieldName) {
      handlePaste.call(this, event, fieldName);
    },
    validatePostalCode() {
      let value = this.clientData.cp;

      value = value.replace(/[^0-9]/g, "");

      this.clientData.cp = value;

      this.validationFeedback =
        value.length === 5 ? "" : "Please enter exactly 5 digits";
    },

    preventNumberInput(event) {
      const key = event.key;

      if (key >= "0" && key <= "9") {
        event.preventDefault();
      }
    },

    removeSpaces(phoneNumber) {
      return phoneNumber.replace(/\s+/g, "");
    },
    formatNumber(event) {
      if (event && event.number && event.country) {
        this.clientData.tel = {
          phone_number: event.number,
          phone_iso_code: event.country.iso2,
        };
        this.resultValid = event.isValid;
      }
    },
    formatNumberE(event) {
      this.clientData.company_tel = {
        phone_number: event.number,
        phone_iso_code: event.country.iso2,
      };
      this.phoneValid = event.isValid;
    },
    validateState(name) {
      const fieldValidation = this.v$.clientData[name];
      if (fieldValidation) {
        const { $dirty, $error } = fieldValidation;
        return $dirty ? !$error : null;
      }
      return null;
    },
    async addCLI() {
      if (this.selected) {
        const client = {
          ...this.clientData,
          revenu_avis_imposition: this.clientData.revenu_avis_imposition,
          phone_iso_code: this.clientData.tel.iso_code,
          company_phone_iso_code: this.clientData?.company_tel?.phone_iso_code,
        };

        if (this.clientData.type === "type.particulier") {
          client.phone_number = this.clientData.tel.phone_number;
        } else {
          const phone = this.cleanPhoneNumber(this.clientData.tel.phone_number);
          client.phone_number = this.removeSpaces(phone);

          const company_phone = this.cleanPhoneNumber(
            this.clientData?.company_tel?.phone_number
          );
          client.company_phone_number = this.removeSpaces(company_phone);
        }
        console.log('dddd')
        await this.edit_client({ data: client });

        const resclient = await this.client(this.clientData?.id);

        const loges = {
          client_id: this.clientData.id,
          adresse: this.logementValue.adresse,
          type: this.logementValue.type,
          proprietaire_occupant: this.logementValue.proprietaire_occupant,
          residence_principale_proprietaire:
            this.logementValue.residence_principale_proprietaire,
          equipement_deposee: this.logementValue.equipement_deposee,
          type_energie_equipement_deposee:
            this.logementValue.type_energie_equipement_deposee,
          periode_de_construction: this.logementValue.periode_de_construction,
          surface: this.logementValue.surface,
          parcelle_cadastrale: this.logementValue.parcelle_cadastrale,
          type_chauffage: this.logementValue.type_chauffage,
        };
        let res;
        // if(this.idLogementData &&this.clientData?.id!= this.getdevisMonoFiche.data.devis.client.id){

        // }
        if (!this.idLogementData) {
          res = await this.storeLogement(loges);
          this.getdevisMonoFiche.data.devis.logement_id = res.data.id;
          this.getdevisMonoFiche.data.devis.logement = {
            id: res.data.id,
          };
        }
        if (this.logementValue?.id) {
          this.getdevisMonoFiche.data.devis.logement_id =
            this.logementValue?.id;
          this.getdevisMonoFiche.data.devis.logement = {
            id: this.logementValue?.id,
            adresse: this.logementValue?.adresse,
            type: this.logementValue?.type,
            proprietaire_occupant: this.logementValue?.proprietaire_occupant,
            residence_principale_proprietaire:
              this.logementValue?.residence_principale_proprietaire,
            equipement_deposee: this.logementValue?.equipement_deposee,
            type_energie_equipement_deposee:
              this.logementValue?.type_energie_equipement_deposee,
            periode_de_construction:
              this.logementValue?.periode_de_construction,
            surface: this.logementValue?.surface,
            parcelle_cadastrale: this.logementValue?.parcelle_cadastrale,
            type_chauffage: this.logementValue?.type_chauffage,
          };
        }

        const menage = {
          client_id: this.clientData.id,
          revenu: this.menageValue.revenu,
          personnes: this.menageValue.personnes,
          commune: this.menageValue.commune,
        };
        let resMenage = resclient.data.menage;
        if (!resclient.data.menage) {
          resMenage = await this.storeMenage(menage);
        }
        if (
          this.getdevisMonoFiche &&
          this.getdevisMonoFiche.data &&
          this.getdevisMonoFiche.data.devis
        ) {
          // this.getdevisMonoFiche.data.devis.logement_id = res.data.id;

          this.getdevisMonoFiche.data.devis.client_id = this.clientData.id;
        }

        this.getdevisMonoFiche.data.devis.client = {
          id: this.clientData.id,
          company_phone_iso_code: client?.company_tel?.phone_iso_code,
          precarite: this.clientData?.precarite,
          pays: this.clientData?.pays,
          nom: this.clientData?.nom,
          prenom: this.clientData?.prenom,
          rue: this.clientData?.rue,
          zone: this.clientData?.zone,
          type: this.clientData?.type,
          ville: this.clientData?.ville,
          phone: resclient?.data.phone,
          company_phone: resclient?.data.company_phone,
          cp: this.clientData?.cp,
          email: this.clientData?.email,
          company: this.clientData?.company,
          company_email: this.clientData?.company_email,
          SWIFT_BIC: this.clientData?.SWIFT_BIC,
          naf: this.clientData?.naf,
          ape: this.clientData?.ape,
          forme_juridique: this.client?.forme_juridique,
          revenu_avis_imposition: this.clientData?.revenu_avis_imposition,
          IBAN: this.clientData?.IBAN,
          titulaire_compte: this.clientData?.titulaire_compte,
          poste: this.clientData?.poste,
          commentaire: this.clientData?.commentaire,
          company_tel: resclient.company_tel,
          logements: this.clientData?.logements,
          menage: {
            id: resMenage.id,
          },
        };

        this.setDevisMonoFiche(this.getdevisMonoFiche);
        return { logement: res, client: this.clientData };
      } else {
        if (this.clientAdd) {
          const data = {
            ...this.clientData,
            pays: this.clientData.pays.iso_code,
            phone_iso_code: this.clientData.tel.phone_iso_code,
            phone_number: this.clientData.tel.phone_number,

            tel: {
              phone_iso_code: this.clientData.tel.phone_iso_code,
              phone_number: this.clientData.tel.phone_number,
            },
          };

          if (this.clientData.type === "type.professionnel") {
            data.company_tel = {
              phone_number: this.clientData.company_tel.phone_number,
              phone_iso_code: this.clientData.company_tel.phone_iso_code,
            };
          }
          if (this.clientData.tel && this.clientData.tel.phone_number) {
            var num = this.clientData.tel.phone_number.indexOf(" ");
            data.phone_number = this.clientData.tel.phone_number
              .slice(num + 1)
              .split(" ")
              .join("");
            data.tel = data.phone_number;
            data.phone_iso_code = this.clientData.tel.phone_iso_code;
          }

          if (
            this.clientData.company_tel &&
            this.clientData.company_tel.phone_number
          ) {
            var numE = this.clientData.company_tel.phone_number.indexOf(" ");
            data.company_phone_number = this.clientData.company_tel.phone_number
              .slice(numE + 1)
              .split(" ")
              .join("");

            data.company_tel = data.company_phone_number;
            data.company_phone_iso_code =
              this.clientData?.company_tel?.phone_iso_code;
          }

          data.entreprise_id = this.getdevisMonoFiche.data.devis.entreprise_id;

          data.precarite = this.clientData.precarite;

          const isFormCorrect = await this.v$.$validate();
          if (!isFormCorrect) return;
          this.erreurlist = {
            nom: null,
            prenom: null,
            email: null,
            rue: null,
            zone: null,
            cp: null,
            ville: null,
            tel: null,
            type: null,
            pays: null,
            SWIFT_BIC: null,
            forme_juridique: null,
            ape: null,
            naf: null,
            IBAN: null,
            titulaire_compte: null,
            commentaire: null,
            company: null,
            company_email: null,
            company_tel: null,
            precarite: null,
            type_chauffage: null,
            type_logement: null,
          };

          this.errorS = null;

          if (this.resultValid == false) return;
          if (this.clientData.company_tel && this.phoneValid == false) return;
          try {
            if (data.type === "type.particulier") {
              delete data.IBAN;
              delete data.SWIFT_BIC;
              delete data.forme_juridique;
              delete data.naf;
              delete data.ape;
              delete data.commentaire;
              delete data.company;
              delete data.company_email;
              delete data.company_phone;
              delete data.company_phone_iso_code;
              delete data.company_tel;
              delete data.poste;
            }
            const response = await this.store_clients(data);
            console.log('response: ' , response)
            this.$emit("error-response", response.status_code);
            if (response.status_code === 422 || response.status_code === 409) {
              this.$refs.snackbar.showSnackbar("e-mail existe déjà");
              this.err = true;
             

              this.isLoading = false;
              return ;
            }

            this.errorS = null;
            const loges = {
              client_id: response.id,
              adresse: this.logementValue.adresse,
              type: this.logementValue.type,
              proprietaire_occupant: this.logementValue.proprietaire_occupant,
              residence_principale_proprietaire:
                this.logementValue.residence_principale_proprietaire,
              equipement_deposee: this.logementValue.equipement_deposee,
              type_energie_equipement_deposee:
                this.logementValue.type_energie_equipement_deposee,
              periode_de_construction:
                this.logementValue.periode_de_construction,
              surface: this.logementValue.surface,
              parcelle_cadastrale: this.logementValue.parcelle_cadastrale,
              type_chauffage: this.logementValue.type_chauffage,
            };

            const res = await this.storeLogement(loges);
            const menage = {
              client_id: response.id,
              revenu: this.menageValue.revenu,
              personnes: this.menageValue.personnes,
              commune: this.menageValue.commune,
            };
            const resmenage = await this.storeMenage(menage);
            this.getdevisMonoFiche.data.devis.logement_id = res.data.id;
            this.getdevisMonoFiche.data.devis.client_id = response.id;
            this.getdevisMonoFiche.data.devis.logement = {
              id: res.data.id,
            };

            this.getdevisMonoFiche.data.devis.client = {
              id: response.id,
              precarite: this.clientData.precarite,
              pays: this.clientData.pays,
              nom: this.clientData.nom,
              prenom: this.clientData.prenom,
              rue: this.clientData.rue,
              zone: this.clientData.zone,
              type: this.clientData.type,
              ville: this.clientData.ville,
              tel: this.clientData.tel,
              phone: this.clientData.phone,
              company_phone: this.clientData.company_phone,
              cp: this.clientData.cp,
              email: this.clientData.email,
              company: this.clientData.company,
              company_email: this.clientData.company_email,
              SWIFT_BIC: this.clientData.SWIFT_BIC,
              forme_juridique: this.clientData.forme_juridique,
              naf: this.clientData.naf,
              ape: this.clientData.ape,
              revenu_avis_imposition: this.clientData.revenu_avis_imposition,
              IBAN: this.clientData.IBAN,
              titulaire_compte: this.clientData.titulaire_compte,
              poste: this.clientData.poste,
              commentaire: this.clientData.commentaire,
              company_tel: this.clientData.company_tel,
              logements: this.clientData.logements,
              menage: {
                id: resmenage.id,
              },
            };

            this.setDevisMonoFiche(this.getdevisMonoFiche);
            return { logement: res, client: response };
          } catch (err) {
            if (this.isObject(err)) {
              for (const [key, value] of Object.entries(err)) {
                if (!this.erreurlist[key]) {
                  this.erreurlist[key] = value;
                }
              }
            } else {
              this.errorS = err;
            }
          }
        }
      }
    },
    cleanPhoneNumber(phoneNumber) {
      return phoneNumber.replace(/^\+\d+\s*/, "");
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },

    selectclient(client) {
      this.clientData = { ...client };
      this.getdevisMonoFiche.data.devis.client = { ...client };
      this.selected = true;
      this.disabled = true;
    },
  },
};
</script>
